.pc-footer-wrap {
  width: 100%;
  min-width: 1200px;
  background: #fff;
  color: #444;
  .footer-inner {
    margin: 0 auto;
    width: 1200px;
    min-height: 100px;
    .footer-top {
      display: flex;
      padding: 30px 0 15px 0;
      font-size: 14px;
      .footer-top-item {
        flex: 1;
        color: #666;
        font-size: 12px;
        &:last-child {
          min-width: 450px;
        }
        .footer-top-item-title {
          font-size: 14px;
          padding-bottom: 5px;
        }
        .footer-top-item-link {
          display: block;
          padding: 3px 0;
          a {
            color: #888c94;
          }
        }
        .footer-top-item-text {
          padding: 3px 0;
          .gtjaqh {
            margin-right: 5px;
            font-size: 14px;
          }
        }
      }
    }
    .footer-bottom {
      padding: 20px 0 30px;
      font-size: 12px;
      border-top: 1px solid #E4E4E4;
      text-align: center;
      .footer-bottom-top {
        margin-bottom: 2px;
        color: #666;
        .friend-link-wrap {
          width: 136px;
          height: 30px;
          display: inline-block;
          vertical-align: middle;
          position: relative;
          color: #666;
          text-align: left;
          .friend-link-show {
            width: 136px;
            height: 30px;
            border: 1px solid #E4E4E4;
            cursor: pointer;
            .friend-link-show-title {
              display: inline-block;
              vertical-align: middle;
              background: #fff;
              color: #999;
              width: 104px;
              height: 26px;
              line-height: 26px;
              padding: 0 5px;
            }
            .friend-link-show-arrow {
              display: inline-block;
              vertical-align: middle;
              width: 30px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              .gtjaqh {
                color: #666;
              }
            }
          }
          .friend-link-list-wrap {
            position: absolute;
            top: -320px;
            left: 0;
            background: #fff;
            width: 210px;
            height: 320px;
            padding-bottom: 10px;
            display: none;
            &::before {
              content: '';
              position: absolute;
              bottom: -9px;
              left: 50px;
              border-top: solid 10px #fff;
              border-bottom: solid 10px transparent;
              border-left: solid 10px transparent;
              border-right: solid 10px transparent;
              z-index: 2;
            }
            &::after {
              content: '';
              position: absolute;
              bottom: -10px;
              left: 50px;
              border-top: solid 10px #ccc;
              border-bottom: solid 10px transparent;
              border-left: solid 10px transparent;
              border-right: solid 10px transparent;
              z-index: 1;
            }
          }
          .friend-link-list {
            width: 100%;
            height: 100%;
            padding: 10px;
            overflow-y: auto;
            border: 1px solid #ccc;
            border-radius: 5px;
            box-shadow: 0px 0px 20px #ccc;
            &::-webkit-scrollbar {
              width: 5px;
              height: 1px;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 5px;
              background: #ccc;
            }
            &::-webkit-scrollbar-track {
              border-radius: 5px;
              background: #eee;
            }
            .friend-link-list-item {
              width: 100%;
              font-size: 14px;
              line-height: 20px;
              padding: 8px 0;
            }
          }
          &:hover {
            .friend-link-list-wrap {
              display: block;
            }
            .friend-link-show {
              .friend-link-show-arrow {
                transform: rotate(180deg);
              }
            }
          }
        }
        .copyright-icon {
          width: 18px;
          display: inline-block;
          vertical-align: middle;
          margin-left: 20px;
        }
        .bottom-top-text {
          display: inline-block;
          vertical-align: middle;
          margin-left: 2px;
        }
        .ipv6-icon {
          display: inline-block;
          vertical-align: middle;
          margin-left: 5px;
          border: solid 2px #0081CC;
          padding: 0px 4px;
          font-size: 12px;
          line-height: 12px;
          border-radius: 8px;
          color: #0081CC;
          font-style: italic;
        }
      }
      .footer-bottom-bottom {
        margin-top: 5px;
        color: #666;
        a {
          &:last-child {
            margin-left: 15px;
          }
        }
        .bottom-bottom-text {
          display: inline-block;
          vertical-align: middle;
        }
        .beian-icon {
          display: inline-block;
          vertical-align: middle;
          margin: 0 3px 0 20px;
        }
      }
    }
  }
}