@font-face {
  font-family: "gtjaqh"; /* Project id 1800660 */
  src: url('iconfont.woff2?t=1659341741552') format('woff2'),
       url('iconfont.woff?t=1659341741552') format('woff'),
       url('iconfont.ttf?t=1659341741552') format('truetype');
}

.gtjaqh {
  font-family: "gtjaqh" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-zichanguanli:before {
  content: "\e636";
}

.icon-lianghuajiaoyi:before {
  content: "\e634";
}

.icon-jigoujinrong:before {
  content: "\e635";
}

.icon-fengxianguanli:before {
  content: "\e638";
}

.icon-jiaogebaozhi:before {
  content: "\e639";
}

.icon-wangluojinrong:before {
  content: "\e63a";
}

.icon-qihuokaihu:before {
  content: "\e62c";
}

.icon-Appxiazai:before {
  content: "\e62e";
}

.icon-guanzhuwomen:before {
  content: "\e62f";
}

.icon-fangzhenkaihu1:before {
  content: "\e630";
}

.icon-jiaoyitishi:before {
  content: "\e632";
}

.icon-guanyuwomen:before {
  content: "\e633";
}

.icon-zhongyingwen:before {
  content: "\e7a3";
}

.icon-chahao:before {
  content: "\e62a";
}

.icon-biyan:before {
  content: "\e6ba";
}

.icon-anquan:before {
  content: "\ec4d";
}

.icon-renyuan:before {
  content: "\e62b";
}

.icon-fangdajing:before {
  content: "\e603";
}

.icon-up:before {
  content: "\e631";
}

.icon-bianji:before {
  content: "\e637";
}

.icon-720bianjiqi_quanxuan:before {
  content: "\e65f";
}

.icon-exportdaochu:before {
  content: "\e6c8";
}

.icon-tuichu:before {
  content: "\e75d";
}

.icon-junhong_icon1:before {
  content: "\e600";
}

.icon-jiaoyi1:before {
  content: "\e628";
}

.icon-fangzhenkaihu:before {
  content: "\e629";
}

.icon-shuoming:before {
  content: "\e623";
}

.icon-xiaochengxu:before {
  content: "\e669";
}

.icon-shang:before {
  content: "\e68a";
}

.icon-jiaoyishuju:before {
  content: "\e7e8";
}

.icon-youjian:before {
  content: "\e70c";
}

.icon-zuojian:before {
  content: "\e70d";
}

.icon-fanggepailie:before {
  content: "\e627";
}

.icon-weizhi:before {
  content: "\e624";
}

.icon-kefu1:before {
  content: "\e625";
}

.icon-gw_magnify:before {
  content: "\e93c";
}

.icon-menu:before {
  content: "\e6f5";
}

.icon-close-circle-outline:before {
  content: "\e6b6";
}

.icon-close:before {
  content: "\e6b7";
}

.icon-window-close:before {
  content: "\e93b";
}

.icon-gw_lock:before {
  content: "\e622";
}

.icon-renming:before {
  content: "\e612";
}

.icon-rili1:before {
  content: "\e75b";
}

.icon-gw_menu-down:before {
  content: "\e7e9";
}

.icon-menu-right:before {
  content: "\e7eb";
}

.icon-ditu1:before {
  content: "\e621";
}

.icon-dianhua1:before {
  content: "\e620";
}

.icon-gongsilogo:before {
  content: "\e61f";
}

.icon-yepan:before {
  content: "\e61c";
}

.icon-ripan:before {
  content: "\e619";
}

.icon-dianhua:before {
  content: "\e671";
}

.icon-zixunxuanzhong:before {
  content: "\e626";
}

.icon-kefu:before {
  content: "\e62d";
}

.icon-xia:before {
  content: "\e725";
}

.icon-weibo:before {
  content: "\e61a";
}

.icon-bianzu:before {
  content: "\e61e";
}

.icon-NEW:before {
  content: "\e61d";
}

.icon-erweima:before {
  content: "\e61b";
}

.icon-guanzhu:before {
  content: "\e613";
}

.icon-kefu10081CC:before {
  content: "\e614";
}

.icon-zixun:before {
  content: "\e615";
}

.icon-chanpin:before {
  content: "\e616";
}

.icon-jisuanqi:before {
  content: "\e617";
}

.icon-gupiao-copy1:before {
  content: "\e618";
}

.icon-zichan:before {
  content: "\e60c";
}

.icon-xiaoshou:before {
  content: "\e60d";
}

.icon-yinhang:before {
  content: "\e60e";
}

.icon-gupiao-copy:before {
  content: "\e60f";
}

.icon-gudingshouyi:before {
  content: "\e610";
}

.icon-caifu:before {
  content: "\e611";
}

.icon-jiaoyi:before {
  content: "\e60a";
}

.icon-kaihu1:before {
  content: "\e60b";
}

.icon-tishi:before {
  content: "\e607";
}

.icon-rili:before {
  content: "\e608";
}

.icon-gonggao:before {
  content: "\e609";
}

.icon-ditu:before {
  content: "\e602";
}

.icon-yingyeting:before {
  content: "\e604";
}

.icon-menu_calendartpl1:before {
  content: "\e605";
}

.icon-kaihu:before {
  content: "\e606";
}

.icon-weixin:before {
  content: "\e601";
}

