.open-account-wrap {
  .open-status {
    .inner-step {
      display: flex;
      width: 654px;
      height: 26px;
      line-height: 26px;
      margin: 30px auto 0;
      // text-align: center;
      color: #fff;
      background-color: #aaa;
      text-align: right;
    }

    .inner-step > div {
      width: 50%;
    }

    .inner-step > div img {
      margin: 0 3px;
    }

    .inner-step > div.currentProgress {
      background-color: #0081cc;
      position: relative;
      .step-text{
        position: absolute;
        top: 1px;
        right:15px;
      }

    }
    
    .inner-step > div.fr {
      position: relative;
      .step-text{
        position: absolute;
        top: 1px;
        right:15px;
      }
    }

    .clearFloat {
      zoom: 1;
    }

    .form-hide {
      display: none;
    }

    .form-show {
      display: block;
    }

    #account-form {
      width: 470px;
      margin: 0 auto;
      padding-top: 30px;
      color: #666;
      font-size: 12px;

      .tip {
        color: #dd3a23;
      }

      .tip label {
        width: 110px;
        font-size: 12px;
        margin-left: 1%;
        line-height: 30px;
        text-align: right;
        display: inline-block;
      }

      .formItem {
        margin-bottom: 30px;
        position: relative;

        .formItem-label {
          width: 100px;
          font-size: 12px;
          line-height: 30px;
          text-align: right;
          display: inline-block;
        }

        .formItem-input {
          width: 320px;
          height: 30px;
          border: 1px solid #e4e4e4;
          border-radius: 2px;
          color: #666;
          margin-left: 15px;
          padding-left: 2px;
          text-indent: 12px;
          vertical-align: top;
        }
        .formItem-tip {
          color: #dd3a23;
          font-size: 12px;
          line-height: 20px;
          margin-top: 2px;
          margin-left: 31%;
          position: absolute;
        }
      }

      .valid-pic {
        margin-bottom: 30px;
        position: relative;

        .formItem-label {
          width: 100px;
          font-size: 12px;
          line-height: 30px;
          text-align: right;
          display: inline-block;
        }

        .formItem-input {
          width: 220px;
          height: 30px;
          border: 1px solid #e4e4e4;
          border-radius: 2px;
          color: #666;
          padding-left: 2px;
          margin-left: 15px;
          text-indent: 12px;
          vertical-align: top;
        }

        img {
          width: 78px;
          height: 30px;
          vertical-align: bottom;
          margin-left: 10px;
          cursor: pointer;
          position: absolute;
        }
      }

      .valid-text {
        position: relative;
      }

      .valid-text input {
        width: 220px !important;
      }

      .valid-text button {
        position: absolute;
        display: inline-block;
        text-align: center;
        width: 90px;
        height: 28px;
        margin-left: 10px;
        color: #fff;
        background-color: #0081cc;
        border: 1px solid #0081cc;
        border-radius: 2px;
        cursor: pointer;
        top: 1px;
      }

      .pcAddress {
        display: block;
        .ant-space{
          margin-left: 12px;
        }
      }

      .mbAddress {
        display: none;
      }

      .pcDepartment {
        display: block;
      }

      .mbDepartment {
        display: none;
      }

      .submitItem {
        text-align: center;
      }

      .submitItem button {
        width: 110px;
        height: 36px;
        color: #fff;
        background-color: #0081cc;
        border: 1px solid #0081cc;
        border-radius: 6px;
        margin-top: 20px;
        cursor: pointer;
      }

      .pcDepartment {
        position: relative;
      }

      .pcDepartment input {
        width: 100px;
      }

      .pcDepartment .select-dep {
        width: 215px;
        display: inline-block;
        position: absolute;
        margin-left: 5px;
      }

      .mb-city-selector {
        width: 67%;
        height: 0.8rem;
        line-height: 0.8rem;
        padding-left: 0.13rem;
        border-radius: 0.05rem;
        margin-left: 0;
        border: 0 none;
        color: #666;
        text-indent: 0;
        font-size: 0.34rem;
      }
    }
  }
}
.container-hide {
  display: none;
}

@media screen and (max-width: 720px) {
  .open-account-wrap .open-status {
    .inner-step {
      display: none;
    }
    #account-form {
      width: 100%;
      margin: 0 auto;
      color: #666;
      font-size: 0.37rem ;
      padding-top: 0.27rem ;
      background-color: #fff;

      .tip {
        color: #dd3a23 !important;
        border: none;
        margin-bottom: 0;

        label {
          width: auto;
          font-size: 0.37rem ;
          line-height: 0.8rem ;
          text-align: justify;
          text-align-last: justify;
          display: inline-flex;
          justify-content: space-between;
          margin-left: 0;
        }
      }

      .formItem {
        border-bottom: 0.03rem solid #e4e4e4;
        padding: 0.27rem 0;
        height: 1.65rem ;
        margin-bottom: 0;

        .formItem-label {
          width: 26%;
          font-size: 0.37rem;
          line-height: 0.8rem;
          text-align: justify;
          text-align-last: justify;
          justify-content: space-between;
        }

        .formItem-input {
          width: 67%;
          height: 0.8rem ;
          line-height: 0.8rem;
          padding-left: 0.13rem;
          border-radius: 0.05rem ;
          margin-left: 0;
          border: 0 none;
          color: #666;
          text-indent: 0;
          font-size: 0.34rem;
        }
      }

      .pcAddress {
        display: none;
      }
      .mbAddress {
        display: block;
      }
      .pcDepartment {
        display: none;
      }
      .mbDepartment {
        display: block;
      }
      .valid-text {
        input {
          width: 38% !important;
        }
        .validBtn {
          position: relative;
          text-align: center;
          width: 2.35rem;
          height: 0.75rem;
          line-height: 0.75rem;
          color: #fff;
          background-color: #0081cc;
          border-radius: 0.05rem ;
          float: right;
          margin-right: 0.13rem ;
        }
      }
      .valid-pic {
        border-bottom: 0.03rem solid #e4e4e4;
        padding: 0.27rem 0;
        height: 1.65rem;
        font-size: 0.37rem;
        margin-bottom: 0;

        label {
          width: 27%;
          font-size: 0.37rem;
          line-height: 0.8rem;
          text-align: justify;
          text-align-last: justify;
          display: inline-flex;
          justify-content: space-between;
        }

        input {
          border: 0 none;
          width: 38% !important;
          height: 0.8rem;
          line-height: 0.8rem;
          padding-left: 0.13rem;
          border-radius: 0.05rem;
          color: #666;
          margin-left: 0;
          text-indent: 0;
        }

        img {
          width: 2.08rem;
          height: 0.8rem;
          float: right;
          margin: 0 0.13rem 0.27rem 0;
          position: relative;
        }
      }
      .pcPicker {
        display: none !important;
      }

      .mbPicker {
        display: block;
      }

      .pcDepartment {
        display: none;
        input {
          border: 0 none;
        }
      }

      .mbDepartment {
        display: block;
      }

      .submitItem {
        text-align: center;
        border: none !important;
        margin-left: 0.27rem;
        

        button {
          width: 100%;
          height: 0.96rem;
          color: #fff;
          background-color: #0081cc;
          border: 1px solid #0081cc;
          border-radius: 0.48rem;
          margin-top: 0.6rem;
        }
      }
    }
  }
}
