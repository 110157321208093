.home-page {
  width: 100%;
  height: 100%;
  overflow: auto;
  color: #3c3c3c;

  .main-container {
    background: #fff;
    width: 1200px;
    margin: 0 auto;
    padding: 20px 50px 100px;
    margin-bottom: 20px;
    .main-tabs {
      width: 100%;
      .tabs-item {
        margin-right: 30px;
        display: inline-block;
        vertical-align: middle;
        line-height: 32px;
        font-size: 14px;
        color: #333;
        cursor: pointer;
        span {
          display: inline-block;
          border-bottom: 3px solid transparent;
        }
        &.active {
          color: #0081cc;
          span {
            display: inline-block;
            border-color: #0081cc;
          }
        }
      }
    }
  }
  .main-container-mb {
    display: none;
    padding: 0.42667rem 0.4rem 0;
    .main-container-mb-card {
      box-sizing: border-box;
      padding: 0.37333rem 0.42667rem 0.48rem 0.42667rem;
      background: #fff;
      border-radius: 0.21333rem;
      height: 3.89333rem;
      opacity: 1;
      &.search {
        margin-top: 0.42667rem;
        .card-inner {
          .card-left {
            span {
              color: #ffad00;
            }
          }
        }
      }
      .card-title {
        color: #3d3d3d;
        font-size: .42667rem;
        height: 0.58667rem;
        line-height: .58667rem;
        font-weight: 700;
      }
      .card-inner {
        margin-top: 0.32rem;
        height: 2.13333rem;
        background: rgba(255,105,0,.08);
        border-radius: 0.21333rem 0.21333rem 0.21333rem 0.21333rem;
        padding: 0 0.4rem 0 0.48rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        .card-left {
          span {
            display: inline-block;
            vertical-align: middle;
            font-size: .48rem;
            color: #ff6900;
            line-height: .48rem;
            font-weight: 900;
          }
          .img-arrow {
            width: 0.42667rem;
            margin-left: 0.10667rem;
            vertical-align: bottom;
          }
        }
        .card-img {
          height: 1.44rem;
          vertical-align: middle;
        }
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .home-page {
    background-color: #f8f8f8;
    color: #3c3c3c;
    .pc-header-wrap {
      display: none;
    }
    .pc-banner-wrap {
      display: none;
    }
    .pc-footer-wrap {
      display: none;
    }
    .main-container {
      width: 100%;
      padding: 0px 20px 50px;
      display: none;
      .main-tabs {
        padding-top: 20px;
        border-bottom: solid 1px #ccc;
        .tabs-item {
          width: 50%;
          margin-right: 0;
          text-align: center;
        }
      }
    }
    .main-container-mb {
      display: block;
    }
  }
}
