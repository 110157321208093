.mb-form-item-wrap {
  width: 100%;
  &.hasImg {
    .mb-form-input {
      input {
        padding-left: 0;
        width: 4.8rem;
        margin-left: -0.05rem;
      }
      textarea {
        padding-left: 0;
        margin-left: -0.05rem;
        width: 4.8rem;
      }
    }
  }
  &.mutl {
    .mb-form-input {
      height: 2rem;
    }
  }
  .mb-form-input {
    height: 1.33333rem;
    padding: 0.45333rem 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-bottom: 0.02667rem solid #f3f3f3;
    position: relative;
    .label {
      font-size: .4rem;
      color: #2c2c2c;
      height: 0.42667rem;
      line-height: .42667rem;
      display: inline-block;
      width: 3rem;
    }
    input {
      background: transparent;
      border: none;
      outline: none;
      font-size: .4rem;
      color: #2c2c2c;
      height: 0.53333rem;
      width: 7rem;
      padding-left: 0.2rem;
    }
    textarea {
      background: transparent;
      border: none;
      outline: none;
      font-size: .4rem;
      color: #2c2c2c;
      width: 7rem;
      padding-left: 0.2rem;
      resize: none;
    }
    .form-item-img-wrap {
      width: 2.13333rem;
      height: 0.90667rem;
      background: #d8d8d8;
      position: absolute;
      right: 0;
      .form-item-img {
        height: 0.90667rem;
        width: 100%;
        border: 0 none;
      }
    }
  }
  .mb-form-tip {
    padding: 0.21333rem 0 0.24rem 0;
    font-size: .34667rem;
    color: #ef3434;
    line-height: .37333rem;
    border-top: solid 1px #ef3434;
  }
}