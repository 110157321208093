.application {
  display: flex;
  flex-direction: column;
}

.application img {
  display: block;
  margin:60px auto 0;
  width: 200px;
  margin-bottom: 1%;
}

.application .achieve-text {
  text-align: center;
  color: #666;
  font-size: 14px;
  line-height: 25px;
}

@media screen and (max-width: 720px) {
  .application img {
    display: block;
    margin: 1.33rem auto 0;
    width: 5.33rem;
    margin-bottom: 5%;
  }
}