* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
}
body {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
html,
body {
  width: 100%;
  height: 100%;
  background: #f7f7f7 !important;
  overflow: hidden;
  color: #31353d;
  a {
    color: #31353d;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
    color: #0081cc;
  }
  p {
    margin: 0;
    padding: 0;
  }
  h1,h2,h3,h4,h5,h6 {
    margin: 0;
    padding: 0;
  }
  input {
    outline: none;
  }
  table {
    border-collapse: collapse;
  }
}
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
// 清除浮动
.clearfix::after {
  content: '.';
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
}
.clearfix {
  zoom: 1;
}
// 全局loading
#global-loading-wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.3);
  z-index: 1000;
  .global-loading-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 25px;
    height: 25px;
    transform: translate3d(-20px, -20px, 0);
    line-height: 0;
    animation: loadingRouting 1.5s linear infinite;
    .global-loading-inner-item {
      border-radius: 50%;
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #bae7ff;
      line-height: 0;
      &:nth-child(2) {
        background: #69c0ff;
        margin-left: 5px;
      }
      &:nth-child(3) {
        background: #40a9ff;
        margin-top: 5px;
      }
      &:nth-child(4) {
        background: #1890ff;
        margin-left: 5px;
        margin-top: 5px;
      }
    }
  }
}
@keyframes loadingRouting {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

.ant-pagination {
  padding-top: 25px;
  padding-bottom: 10px;
  text-align: center;
  text-align: center;
}

