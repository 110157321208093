.error-page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  .ant-result-404 {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -300px;
  }
}