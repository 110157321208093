.mb-account-search-page {
  width: 100%;
  background-color: #f8f8f8;
  .search-btn {
    height: 1.17333rem;
    line-height: 1.17333rem;
    margin: 0.74667rem 0.4rem 0 0.4rem;
    text-align: center;
    font-size: .42667rem;
    color: #fff;
    background: #ff6900;
    border-radius: 0.10667rem;
    opacity: 1;
    cursor: pointer;
    &.disable {
      opacity: .5;
    }
  }
  .search-form {
    background: #fff;
    padding: 0 0.4rem;
  }
  .search-result {
    width: 100%;
    padding: 0 0.5rem;
    margin-top: 0.5rem;
    text-align: center;
    font-size: 0.4rem;
    .copy-btn {
      color: #1677ff;
    }
  }
}