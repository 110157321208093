.account-search-wrap {
  width: 100%;
  min-height: 500px;
  #open-form {
    width: 470px;
    margin: 0 auto;
    padding-top: 30px;
    color: #666;
    font-size: 12px;
  
    .formItem {
      margin-bottom: 30px;
      position: relative;
  
      label {
        width: 100px;
        font-size: 12px;
        line-height: 30px;
        text-align: right;
        display: inline-block;
      }
  
      .ant-input {
        width: 320px;
        height: 30px;
        border: 1px solid #E4E4E4;
        border-radius: 2px;
        color: #666;
        margin-left: 5px;
        padding-left: 2px;
        text-indent: 12px;
        vertical-align: top;
      }
      .ant-form-item-explain-error{
        margin-left: 5%;
      }
    }
    .valid-pic {
      margin-bottom: 30px;
      position: relative;
      .ant-form-item-explain-error{
        margin-left: 5%;
      }
      label {
        width: 100px;
        font-size: 12px;
        line-height: 30px;
        text-align: right;
        display: inline-block;
      }
  
      .ant-input {
        width: 220px;
        height: 30px;
        border: 1px solid #e4e4e4;
        border-radius: 2px;
        color: #666;
        padding-left: 2px;
        margin-left: 5px;
        text-indent: 12px;
        vertical-align: top;
      }
  
      img {
        width: 78px;
        height: 30px;
        vertical-align: bottom;
        margin-left: 10px;
        bottom:5px;
        cursor: pointer;
        position: absolute;
      }
    }
    .submit-item {
      text-align: center;
    }
  
    .submit-item button {
      width: 110px;
      height: 36px;
      color: #fff;
      background-color: #0081CC;
      border: 1px solid #0081CC;
      border-radius: 6px;
      margin-top: 20px;
      cursor: pointer;
    }
  }
  .searchResult{
    font-size: 16px;
    color: red;
    text-align: center;
    width: 100%;
  }
}

@media screen and (max-width: 720px) {
  .account-search-wrap {
    #open-form {
      width: 100%;
      
      margin: 0 auto;
      color: #666;
      padding-top: .27rem;

      .formItem {
        position: relative;
        // padding: .27rem 0 0;
        margin-bottom: 0;
        border-bottom: 0.03rem solid #e4e4e4;
        .ant-form-item-label {
          font-size: .37rem;
          line-height: .8rem;
          text-align: justify;
          text-align-last: justify;
          padding: 0;
          height: 0.7rem;
        }
        .ant-form-item-control-input .ant-input {
          width: 100%;
          margin: 0;
          border: 0 none;
        }
        .ant-form-item-explain-error {
          font-size: 12px;
          height: 20px;
          margin-left: 5%;
        }
      }

      .valid-pic {
        position: relative;
        padding: .27rem 0 0;
        margin-bottom: 0;
        border-bottom: 0.03rem solid #e4e4e4;
        .ant-form-item-label {
          font-size: .37rem;
          line-height: .8rem;
          text-align: justify;
          text-align-last: justify;
          padding: 0;
          height: 0.7rem;
        }
        .ant-form-item-control-input {
          width: calc(100% - 90px);
        }
        .ant-form-item-control-input .ant-input {
          width: 100%;
          margin: 0;
          border: 0 none;
        }
        img{
          margin-left: 5px;
        }
        .ant-form-item-explain-error {
          font-size: 12px;
          margin-left: 5%;
        }
      }

      .submit-item {
        text-align: center;
        border: none !important;
        margin-top: 25px;
        .ant-btn {
          width: 100%;
          height: .96rem;
          color: #fff;
          background-color: #0081CC;
          border: .03rem solid #0081CC;
          border-radius: .48rem;
          margin-top: .53rem;
        }
      }
    }
  }
}