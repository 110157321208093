.pc-header-wrap {
  width: 100%;
  min-width: 1200px;
  position: relative;
  .header-top {
    height: 36px;
    background: #0081cc;
    line-height: 36px;
    color: #fff;
    .top-inner {
      margin: 0 auto;
      width: 1200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      .top-inner-left {
        cursor: pointer;
        padding: 0 23px;
        position: relative;
        display: flex;
        align-items: center;
        i {
          font-size: 12px;
          margin-left: 5px;
          position: relative;
          margin-top: 3px;
          line-height: 12px;
        }
        .sub-company-wrap {
          position: absolute;
          display: none;
          top: 36px;
          left: 0;
          width: 200px;
          padding-top: 8px;
          padding-left: 10px;
          background: #fff;
          color: #333;
          border: solid 1px #0081cc;
          border-top: none;
          z-index: 100;

          .sub-company-item {
            overflow: hidden;
            line-height: 12px;

            .sub-company-item-link {
              display: block;
              font-size: 12px;
              padding: 8px 0;
            }
          }
        }

        .anticon-down {
          display: inline;
        }

        .anticon-up {
          display: none;
        }

        &:hover {
          background: #fff;
          color: #31353d;

          .sub-company-wrap {
            display: inline;
          }

          .anticon-down {
            display: none;
          }

          .anticon-up {
            display: inline;
          }
        }
      }
      .top-inner-right {
        display: flex;
        align-items: center;
        .right-item {
          margin-left: 30px;
          cursor: pointer;
          color: #fff;
          .gtjaqh {
            padding-right: 5px;
            font-size: 14px;
          }
          &:hover {
            text-decoration: none;
          }
          .img95521 {
            cursor: auto;
            margin-top: -5px;
          }
          .right-item-icon {
            margin-right: 3px;
            font-size: 16px;
          }
        }
        .top-language {
          width: 60px;
          height: 36px;
          position: relative;
          margin-left: 20px;
          font-size: 12px;
          &:hover {
            .top-language-list {
              display: block;
            }
            .top-language-text {
              background: #fff;
              color: #333;
            }
          }
          .top-language-text {
            line-height: 36px;
            padding-left: 10px;
            cursor: pointer;
            .gtjaqh {
              vertical-align: middle;
              margin-left: 5px;
              font-size: 12px;
            }
          }
          .top-language-list {
            display: none;
            position: absolute;
            top: 36px;
            right: 0;
            width: 100%;
            color: #333;
            background: #ffffff;
            border: 1px solid #0081cc;
            border-top: none;
            z-index: 100;
            .top-language-list-item {
              display: block;
              padding-left: 10px;
              width: 100%;
              height: 36px;
            }
          }
        }
      }
    }
  }
  .header-logo {
    width: 100%;
    height: 90px;
    top: -60px; // 为了动画效果加的
    background: #fff;
    transition: top 0.5s linear;
    .fixed-inner {
      position: relative;
      width: 1200px;
      margin: 0 auto;
      height: 100%;
      display: flex;
      align-items: center;
      .logo-wrap {
        display: inline-block;
        vertical-align: middle;
        width: 250px;
        height: 34px;
        cursor: pointer;

        .logo-img {
          width: 100%;
          height: 100%;
        }
      }
      .member-one-wrap {
        display: inline-block;
        vertical-align: middle;
        width: 98px;
        height: 26px;
        margin: 0 18px;
        cursor: pointer;

        .logo-one {
          width: 100%;
          height: 100%;
        }
      }
      .entry-list {
        .entry-list-item {
          display: inline-flex;
          align-items: center;
          // margin: 0 10px;
          height: 100%;
          a {
            font-size: 16px;
            cursor: pointer;
            display: inline-block;
            height: 60px;
            line-height: 60px;
          }
          a:hover {
            // color: #0081cc;
            border-bottom: 3px solid #0081cc;
          }
        }
        .entry-list-item:not(:first-child) {
          margin-left: 18px;
        }
        .list-card-wrapper {
          position: absolute;
          left: 0;
          z-index: 999;
          width: 100%;
          height: auto;
          background-color: #fff;
          opacity: 0.96;
          padding: 20px;
          display: flex;
          justify-content: space-between;
          box-shadow: 0px 0px 20px #ccc;
          .list-info {
            width: 70%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .card-node-item {
              height: 80px;
              width: 50%;
              margin-bottom: 15px;
              padding-right: 15px;
            }
            .card-node-item h2 {
              display: flex;
              align-items: center;
            }
            .card-node-item h2::before {
              content: ' ';
              display: inline-block;
              height: 25px;
              width: 5px;
              background-color: #0081cc;
              margin-right: 10px;
            }
            .card-node-item p {
              margin-top: 10px;
              padding-left: 15px;
              a {
                display: inline-block;
              }
            }
            .card-node-item a:not(:last-child) {
              padding-right: 20px;
            }
          }
          .list-right {
            width: 30%;
            text-align: center;
            img {
              cursor: pointer;
              max-width: 100%;
            }
            .n-ad {
              width: 180px;
              text-align: left;
              margin-left: 20px;
              h3 {
                font-size: 14px;
              }
            }
          }
        }
        height: 100%;
      }
      .right-icon {
        margin-left: 15%;
        width: 220px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        a {
          color: #31353d;
          i {
            font-size: 20px;
          }
        }
      }
    }
  }
  .fixed-logo {
    width: calc(100% - 16px);
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 1px 2px 5px rgb(0 0 0 / 24%);
    border-top: 2px solid #0081cc;
    z-index: 100;
    .entry-list-item {
      margin-left: 18px;
    }
  }
}