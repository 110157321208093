.mb-open-account-page {
  width: 100%;
  background-color: #f8f8f8;
  .open-btn {
    height: 1.17333rem;
    line-height: 1.17333rem;
    margin: 0.74667rem 0.4rem 0 0.4rem;
    text-align: center;
    font-size: .42667rem;
    color: #fff;
    background: #ff6900;
    border-radius: 0.10667rem;
    opacity: 1;
    cursor: pointer;
    &.disable {
      opacity: .5;
    }
  }
  .open-form {
    background: #fff;
    padding: 0 0.4rem;
    .phone-code-input {
      width: 100%;
      .mb-form-input {
        height: 1.33333rem;
        padding: 0.45333rem 0;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        border-bottom: 0.02667rem solid #f3f3f3;
        position: relative;
        .label {
          font-size: .4rem;
          color: #2c2c2c;
          height: 0.42667rem;
          line-height: .42667rem;
          display: inline-block;
          width: 3rem;
        }
        input {
          background: transparent;
          border: none;
          outline: none;
          font-size: .4rem;
          color: #2c2c2c;
          height: 0.53333rem;
          padding-left: 0;
          margin-left: -0.05rem;
          width: 4rem;
        }
        .form-item-img-wrap {
          font-size: .4rem;
          position: absolute;
          z-index: 100;
          right: 0;
          color: #ff6900;
          border: none;
          background-color: transparent;
          outline: none;
          line-height: .42667rem;
          cursor: pointer;
          .disable {
            color: #d8d8d8;
          }
        }
      }
      .mb-form-tip {
        padding: 0.21333rem 0 0.24rem 0;
        font-size: .34667rem;
        color: #ef3434;
        line-height: .37333rem;
        border-top: solid 1px #ef3434;
      }
    }
    .select-wrap {
      width: 100%;
      .mb-form-input {
        height: 1.33333rem;
        padding: 0.45333rem 0;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        border-bottom: 0.02667rem solid #f3f3f3;
        position: relative;
        .label {
          font-size: .4rem;
          color: #2c2c2c;
          height: 0.42667rem;
          line-height: .42667rem;
          display: inline-block;
          width: 3rem;
        }
        input {
          background: transparent;
          border: none;
          outline: none;
          font-size: .4rem;
          color: #2c2c2c;
          height: 0.53333rem;
          padding-left: 0.3rem;
          width: 7rem;
        }
      }
      .mb-form-tip {
        padding: 0.21333rem 0 0.24rem 0;
        font-size: .34667rem;
        color: #ef3434;
        line-height: .37333rem;
        border-top: solid 1px #ef3434;
      }
    }
  }
}